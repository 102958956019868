import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where
} from '@firebase/firestore';
import { getDownloadURL, getMetadata, listAll, ref } from 'firebase/storage';
import LogRocket from 'logrocket';
import { PropTypes } from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db, storage } from 'src/_firebase/firebase';

const UserContext = createContext();

export const CurrentUserProvider = ({ children }) => {
  CurrentUserProvider.propTypes = {
    children: PropTypes.any
  };
  const [user, loading] = useAuthState(auth);
  const [currentUser, setCurrentUser] = useState();
  const [currentUserLoading, setCurrentUserLoading] = useState(false);
  // this is our anchor for loading and user, everything should
  // render according to this in order to forbid delays
  // currentUserLoading is to be true when we retrieve the user's data
  const currentUserData = useMemo(
    () => ({ currentUser, setCurrentUser, user, loading, currentUserLoading }),
    [currentUser, setCurrentUser, user, loading, currentUserLoading]
  );

  const handleUserDataRetrieve = async (emailWithIdPrefix) => {
    const user_id = emailWithIdPrefix.split('@')[0];
    // console.log(userId);
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('id', '==', user_id), limit(1));
    const imagesUrls = [];
    await getDocs(q)
      .then((snapshot) => {
        // since its query limitted to 1 doc, the response will be always 1
        // hence, we return the array in order to pass it to the next 'then' section
        // and use the user_doc_id there
        let temp;
        snapshot.docs.forEach((userDoc) => {
          // setCurrentUser({ ...userDoc.data(), user_doc_id: userDoc.id });
          temp = { ...userDoc.data(), user_doc_id: userDoc.id };
        });
        return temp;
      })
      .then(async (userData) => {
        // console.log(userData);
        if (userData) {
          if (userData.type === 'org') {
            // const { _geoloc } = userData;
            const orgStorageRef = ref(
              storage,
              `orgs/${userData.user_doc_id}/orgPictures`
            );
            const _geoloc = {
              lng:
                userData && userData._geoloc
                  ? userData._geoloc._long || userData._geoloc.lng
                  : 35.11,
              lat:
                userData && userData._geoloc
                  ? userData._geoloc._lat || userData._geoloc.lat
                  : 32.71
            };
            await listAll(orgStorageRef)
              .then(async (listResult) => {
                const picsPromises = listResult.items.map(async (pic) => {
                  const metadata = await getMetadata(pic);
                  const { customMetadata } = metadata;
                  return getDownloadURL(pic).then((picUrl) => {
                    imagesUrls.push({
                      name: pic.name,
                      url: picUrl,
                      full_path: pic.fullPath,
                      metadata: customMetadata
                    });
                  });
                });
                await Promise.all(picsPromises)
                  .then(() => {
                    setCurrentUser({
                      ...userData,
                      _geoloc,
                      images: imagesUrls
                    });
                  })
                  .catch((err) => {
                    console.log('err while uploading promise', err);
                  });
              })
              .catch((err) => {
                console.log('err while listing all pics', err);
              });
          } else {
            // retrieve users workplaces
            const employeeWorkplacesRef = collection(
              db,
              'users',
              userData.user_doc_id,
              'employee_work_places'
            );
            const employee_work_places = [];
            const docsSnapshot = await getDocs(employeeWorkplacesRef);
            if (!docsSnapshot.empty) {
              const workplacesPromises = docsSnapshot.docs.map(
                async (workplace) => {
                  // get org document from users
                  const orgRef = doc(db, `users/${workplace.data().org_ref}`);
                  return getDoc(orgRef)
                    .then(async (orgDoc) => {
                      if (orgDoc.exists()) {
                        const orgSpecificDocument = await getDoc(
                          doc(
                            db,
                            `orgs/${orgDoc.id}/employees/${
                              workplace.data().employee_ref
                            }`
                          )
                        );
                        let org_specific_details_for_employee = {};
                        if (orgSpecificDocument.exists()) {
                          org_specific_details_for_employee = {
                            ...orgSpecificDocument.data()
                          };
                          if (
                            orgSpecificDocument.data().rbac &&
                            orgSpecificDocument.data().rbac.length > 0
                          ) {
                            // get roles permissions
                            const { org_ref } = orgSpecificDocument.data();
                            await orgSpecificDocument
                              .data()
                              .rbac.forEach(async (rbac, index) => {
                                const { role_ref } = rbac;
                                if (role_ref) {
                                  const roleRef = doc(
                                    db,
                                    `orgs/${org_ref}/roles/${role_ref}`
                                  );
                                  await getDoc(roleRef).then((roleDoc) => {
                                    // permissions still good
                                    org_specific_details_for_employee.rbac[
                                      index
                                    ].role = {
                                      ...roleDoc.data()
                                    };
                                  });
                                }
                              });
                          }
                        }
                        const {
                          org_name,
                          phone,
                          type,
                          geolocation,
                          description,
                          dateOfEstablishment,
                          date_of_establishment,
                          address,
                          email,
                          id,
                          _geoloc,
                          org_status,
                          org_specific_details,
                          ...rest
                        } = orgDoc.data();
                        return {
                          ...workplace.data(),
                          org_name,
                          phone,
                          type,
                          geolocation,
                          description,
                          address,
                          date_of_establishment:
                            dateOfEstablishment || date_of_establishment,
                          email,
                          id,
                          _geoloc,
                          org_specific_details:
                            org_specific_details_for_employee,
                          org_status,
                          ...rest
                        };
                      }
                    })
                    .catch((err) => console.log('err workplaces', err));
                }
              );
              Promise.all(workplacesPromises)
                .then((res) => {
                  setCurrentUser({
                    ...currentUser,
                    ...userData,
                    employee_work_places: res.filter((el) => el)
                  });
                })
                .catch((err) => {
                  console.log(err);
                  setCurrentUser({
                    ...currentUser,
                    ...userData,
                    employee_work_places // employee_work_places is empty array hence it wont crash
                  });
                });
            } else {
              setCurrentUser({
                ...currentUser,
                ...userData,
                employee_work_places
              });
            }
          }
        }
      })
      .then(() => setCurrentUserLoading(false))
      .catch((err) => {
        console.log(err);
        setCurrentUserLoading(false);
      });
  };

  useEffect(() => {
    if (user && loading) {
      setCurrentUserLoading(true);
      handleUserDataRetrieve(user.email);
    }
    if (!user && loading) {
      setCurrentUserLoading(true);
    }
    if (user && !loading) {
      handleUserDataRetrieve(user.email);
      setCurrentUserLoading(false);
    }
    if (!user && !loading) {
      setCurrentUserLoading(false);
    }
  }, [user, loading]);

  useEffect(() => {
    if (currentUser) {
      const usersLists = [
        'toku50Exj8TvMOg5WnOq',
        'oCRsyZprNOTmrpj8qRdQ',
        'HmIuVgFq3QCPuTeYIhMD'
      ];
      const { user_doc_id } = currentUser;
      if (usersLists.includes(user_doc_id)) {
        const { type, org_name, first_name, last_name, email, diamonds, id } =
          currentUser;
        LogRocket.identify(user_doc_id, {
          email,
          name: type === 'personal' ? `${first_name} ${last_name}` : org_name,
          id,
          diamonds,
          user_doc_id
        });
      }
    }
  }, [currentUser]);
  return (
    <UserContext.Provider value={currentUserData}>
      {children}
    </UserContext.Provider>
  );
};
export const useCurrentUserContext = () => useContext(UserContext);
