import {
  AttachFileOutlined,
  DeleteOutlineOutlined,
  DescriptionOutlined,
  SendOutlined
} from '@mui/icons-material';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import moment from 'moment';
import PropTypes, { array } from 'prop-types';
import { useEffect, useState } from 'react';
import theme from 'src/theme';
import NewPostLabels from './AddNewPost/NewPostLabels';
import AttachNewFileToPost from './AttachNewFileToPost';
import AttachedFileList from './AttachedFileList';
import BuildKnowledgeTestDialog from './KnowledgeTestDialog/BuildKnowledgeTestDialog';

const AddNewPostCard = ({
  handleSaveNewPost,
  existingPostDetails,
  activeTab,
  posts
}) => {
  AddNewPostCard.propTypes = {
    handleSaveNewPost: PropTypes.func,
    existingPostDetails: PropTypes.object,
    activeTab: PropTypes.string,
    posts: array
  };
  const [newPostValues, setNewPostValues] = useState({
    description: '',
    signature_required: false,
    duration: 0
  });
  const [
    isVerifyDeletingKnowledgeTestOpen,
    setVerifyDeletingKnowledgeTestOpen
  ] = useState(false);

  const [attachNewFileOpen, setAttachNewFileOpen] = useState(false);
  const [isBuildKnowledgeTestDialogOpen, setOpenBuildKnowledgeTestDialog] =
    useState(false);
  const [attachedFiles, setAttachedFiles] = useState();
  useEffect(() => {
    if (existingPostDetails) {
      setNewPostValues(existingPostDetails);
    }
  }, [existingPostDetails]);

  const handleChangeNewPostValues = (e) => {
    setNewPostValues({ ...newPostValues, [e.target.name]: e.target.value });
  };
  const handleOpenAttachNewFileToPost = () => {
    setAttachNewFileOpen(true);
  };
  const handleCloseAttachNewFileToPost = () => {
    setAttachNewFileOpen(false);
  };
  const handleRemoveAttachedFile = () => {
    setAttachedFiles();
    const temp = { ...newPostValues };
    delete temp.file_name;
    delete temp.file;
    setNewPostValues({ ...temp });
  };

  const getTimeOfPost = (post) => {
    const { last_modified, created_at } = post;
    const format = 'HH:mm DD/MM/YYYY';
    if (last_modified) {
      return moment(last_modified.seconds * 1000).format(format);
    }
    if (created_at) {
      return moment(created_at.seconds * 1000).format(format);
    }
    return 'אין מידע';
  };

  const handleOpenBuildKnowledgeTestDialog = () =>
    setOpenBuildKnowledgeTestDialog(
      newPostValues && newPostValues.knowledgeTest
        ? newPostValues.knowledgeTest
        : true
    );
  const handleCloseBuildKnowledgeTestDialog = () =>
    setOpenBuildKnowledgeTestDialog(false);

  const handleSaveNewKnowledgeTestToLocalState = (
    newTestDetails,
    generalDetails
  ) => {
    setNewPostValues({
      ...newPostValues,
      knowledgeTest: newTestDetails,
      ...(generalDetails || {})
    });
    handleCloseBuildKnowledgeTestDialog();
    // const data = await saveKnowledgeTest(
    //   newTestDetails,
    //   org_ref,
    //   currentUser.user_doc_id
    // );
  };

  const handleVerifyDeletingKnowledgeTest = () => {
    setVerifyDeletingKnowledgeTestOpen(true);
  };

  const handleCloseVerifyDeletingKnowledgeTest = () => {
    setVerifyDeletingKnowledgeTestOpen(false);
  };
  const handleRemoveKnowledgeTestFromPost = () => {
    const temp = { ...newPostValues };
    if (temp.knowledgeTest) {
      delete temp.knowledgeTest;
      setNewPostValues(temp);
    }
  };
  useEffect(() => {
    if (activeTab === 'signature_required') {
      setNewPostValues({
        ...newPostValues,
        signature_required: true
      });
    } else {
      setNewPostValues({
        ...newPostValues,
        signature_required: false
      });
    }
  }, [activeTab]);

  return (
    <>
      <Card elevation={0}>
        <CardHeader
          title={
            !existingPostDetails && (
              <>
                {activeTab === 'regular'
                  ? 'הוספת פרסום כללי'
                  : activeTab === 'signature_required'
                  ? 'הוספת פרסום לחתימה'
                  : 'בניית מבדק ידע'}
              </>
            )
          }
          subheader={
            existingPostDetails &&
            `שינוי אחרון היה ב- ${getTimeOfPost(existingPostDetails)}`
          }
          sx={{
            pb: 0
          }}
        />
        <CardContent>
          <TextField
            value={newPostValues.description}
            placeholder="הוסף את התיאור של עדכון זה.."
            variant="standard"
            sx={{
              maxWidth: theme.breakpoints.values.md,
              borderRadius: 1
            }}
            fullWidth
            multiline
            name="description"
            onChange={handleChangeNewPostValues}
            rows={4}
            autoFocus
            helperText={
              activeTab === 'knowledge_tests' &&
              'אחרי הוספת העדכון, תוכל לבחור למי להציג אותו (קבוצות או עובדים ספציפיים)'
            }
            InputProps={{
              startAdornment: (
                <DescriptionOutlined
                  htmlColor={lighten(theme.palette.info.light, 0.35)}
                />
              ),
              disableUnderline: Boolean(true),
              sx: {
                backgroundColor: theme.palette.background.default
              }
            }}
          />
          {activeTab !== 'knowledge_tests' && (
            <Grow in={activeTab !== 'knowledge_tests'}>
              <TextField
                value={newPostValues.duration}
                placeholder="מה משך עדכון זה? (בשעות)"
                helperText="כמה שעות ייספרו לעובד שעבר על עדכון זה - במסגרת סעיף עדכון ידע מקצועי"
                sx={{ maxWidth: 450, mt: 2 }}
                fullWidth
                name="duration"
                type="number"
                onChange={(e) => {
                  handleChangeNewPostValues({
                    target: {
                      value: parseFloat(e.target.value),
                      name: e.target.name
                    }
                  });
                }}
              />
            </Grow>
          )}
          {activeTab !== 'knowledge_tests' && (
            <NewPostLabels
              newPostValues={newPostValues}
              setNewPostValues={setNewPostValues}
            />
          )}
          {activeTab !== 'knowledge_tests' && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(newPostValues.valid_for_all_employees)}
                      onChange={(e, checked) =>
                        handleChangeNewPostValues({
                          target: {
                            name: 'valid_for_all_employees',
                            value: checked
                          }
                        })
                      }
                      inputProps={{
                        'aria-label':
                          'האם עובדים אחרים חוץ מהקבוצה שנדרשת לחתום מותר להם לצפות בעדכון?'
                      }}
                    />
                  }
                  label="האם עובדים אחרים חוץ מהקבוצה שנדרשת לחתום מותר להם לצפות בעדכון?"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        Boolean(newPostValues.signature_required) ||
                        activeTab === 'signature_required'
                      }
                      onChange={(e, checked) =>
                        handleChangeNewPostValues({
                          target: {
                            name: 'signature_required',
                            value: checked
                          }
                        })
                      }
                      inputProps={{
                        'aria-label': 'האם נדרשת חתימה?'
                      }}
                    />
                  }
                  disabled
                  label={
                    activeTab === 'regular'
                      ? 'האם לדרוש חתימה? (לחץ על כרטיסיית פרסומים לחתימה)'
                      : 'האם לדרוש חתימה ? (אם לא נדרשת חתימה, לחץ על פרסומים כלליים)'
                  }
                />
              </Grid>
            </Grid>
          )}
          {newPostValues && newPostValues.file && (
            <AttachedFileList
              attachedFiles={newPostValues.file}
              handleRemoveAttachedFile={handleRemoveAttachedFile}
              setAttachedFiles={setAttachedFiles}
              setNewPostValues={setNewPostValues}
              newPostValues={newPostValues}
            />
          )}
          {newPostValues.knowledgeTest && (
            <Grow in={Boolean(newPostValues.knowledgeTest)}>
              <Alert
                severity="info"
                sx={{ justifyContent: 'center', alignItems: 'center', my: 1 }}
                action={
                  <IconButton
                    onClick={() => handleVerifyDeletingKnowledgeTest()}
                    color="error"
                    size="small"
                  >
                    <DeleteOutlineOutlined />
                  </IconButton>
                }
              >
                <Typography
                  variant="inherit"
                  color="inherit"
                  fontSize="inherit"
                >
                  {`הוספת שאלון שמכיל ${
                    newPostValues.knowledgeTest?.pages?.length || 1
                  } עמודים וסך של ${newPostValues.knowledgeTest?.pages.reduce(
                    (acc, curr) => acc + curr.elements.length,
                    0
                  )} שאלות`}
                </Typography>
              </Alert>
            </Grow>
          )}
        </CardContent>
        <CardActions
          sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }}
        >
          <Stack direction="row" gap={1}>
            {activeTab !== 'knowledge_tests' && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                  color: theme.palette.secondary.main,
                  boxShadow: theme.shadows[1],
                  '&:hover': {
                    backgroundColor: lighten(
                      theme.palette.secondary.light,
                      0.65
                    )
                  }
                }}
                disabled={Boolean(newPostValues.knowledgeTest)}
                startIcon={<AttachFileOutlined />}
                onClick={() => {
                  if (!newPostValues.knowledgeTest) {
                    handleOpenAttachNewFileToPost();
                  }
                }}
              >
                צרף קובץ
              </Button>
            )}
            {activeTab === 'knowledge_tests' && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: lighten(theme.palette.warning.light, 0.85),
                  color: theme.palette.warning.main,
                  boxShadow: theme.shadows[1],
                  '&:hover': {
                    backgroundColor: lighten(theme.palette.warning.light, 0.65)
                  }
                }}
                disabled={Boolean(newPostValues.file || attachedFiles)}
                startIcon={<AttachFileOutlined />}
                onClick={() => {
                  if (!newPostValues.file || !attachedFiles) {
                    handleOpenBuildKnowledgeTestDialog();
                  }
                }}
              >
                בניית שאלון
              </Button>
            )}
          </Stack>
          <Button
            variant="contained"
            color="primary"
            startIcon={
              <SendOutlined
                sx={{
                  transform: 'rotate(-45deg)'
                }}
              />
            }
            disabled={
              activeTab === 'knowledge_tests' && !newPostValues.knowledgeTest
            }
            onClick={() => {
              if (
                activeTab !== 'knowledge_tests' ||
                (activeTab === 'knowledge_tests' && newPostValues.knowledgeTest)
              ) {
                handleSaveNewPost(newPostValues, setNewPostValues);
              }
            }}
          >
            הוספה
          </Button>
        </CardActions>
      </Card>
      <AttachNewFileToPost
        newPostValues={newPostValues}
        setNewPostValues={setNewPostValues}
        open={attachNewFileOpen}
        onClose={handleCloseAttachNewFileToPost}
        handleRemoveAttachedFile={handleRemoveAttachedFile}
        attachedFiles={attachedFiles}
        setAttachedFiles={setAttachedFiles}
      />
      {isBuildKnowledgeTestDialogOpen && (
        <BuildKnowledgeTestDialog
          open={isBuildKnowledgeTestDialogOpen}
          onClose={handleCloseBuildKnowledgeTestDialog}
          handleSaveTest={handleSaveNewKnowledgeTestToLocalState}
          posts={posts}
        />
      )}
      {Boolean(isVerifyDeletingKnowledgeTestOpen) &&
        newPostValues.knowledgeTest && (
          <Dialog
            open={Boolean(isVerifyDeletingKnowledgeTestOpen)}
            onClose={handleCloseVerifyDeletingKnowledgeTest}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>
              <Stack direction="row" gap={1}>
                <Typography variant="inherit">מחיקת שאלון</Typography>
                <Typography variant="inherit" fontWeight={600}>
                  {` - ${newPostValues.knowledgeTest.process_name}`}
                </Typography>
              </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Typography variant="subtitle1">
                האם אתה בטוח שברצונך למחוק את השאלון שבנית ?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleRemoveKnowledgeTestFromPost()}
              >
                מחיקה
              </Button>
              <Button
                // color="error"
                variant="contained"
                onClick={() => handleCloseVerifyDeletingKnowledgeTest()}
              >
                ביטול
              </Button>
            </DialogActions>
          </Dialog>
        )}
    </>
  );
};

export default AddNewPostCard;
