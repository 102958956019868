const marketplaceAcceptedTypes = {
  'video/*': [], // All video types
  'image/*': [], // All image types
  'application/pdf': [], // PDF files
  'text/plain': [], // Text files
  'application/msword': [], // Word documents
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // Word documents (docx)
  'application/vnd.ms-powerpoint': [], // PowerPoint presentations
  // Audio
  'audio/*': [], // All audio types
  // Spreadsheets and Data
  'application/vnd.ms-excel': [], // Excel spreadsheets (xls)
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // Excel spreadsheets (xlsx)
  'text/csv': [], // CSV
  // Presentations and Slides
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    [], // PowerPoint presentations (pptx)
  // Archives
  'application/zip': [], // ZIP archives
  'application/x-rar-compressed': [] // RAR archives
};

const commonConfig = {
  emailAuthSuffix: 'mgdalor.com',
  supportMail: ['support@mgdalor.com', 'mgdalor1@gmail.com'],
  radiusMultiplyFactor: 99999999,
  supportPhone: '972557111106',
  noReplyEmail: 'noreply@mgdalor.com',
  ticketsMail: 'tickets@mgdalor.com',
  revealConfig: {
    keyboard: false,
    controls: true,
    touch: true,
    loop: false,
    controlsLayout: 'edges',
    progress: process.env.REACT_APP_DEV === 'true',
    slideNumber: process.env.REACT_APP_DEV === 'true',
    rtl: true
  },
  coursewaresSize: 12,
  marketplaceAcceptedTypes,
  maxStorage: 20 * 1024 * 1024 * 1024,
  defaultOnBoardingProcess: 'drcTSxGgWo5xQK6fKWrf',
  healthProfessionalsJobs: [
    'socialWorker',
    'physicalTherapist',
    'occupationalTherapist',
    'dietitian',
    'communicationalTherapy',
    'pharmacist'
  ]
};

export default commonConfig;
