import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  where
} from '@firebase/firestore';
import { Grid, Paper } from '@mui/material';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { isUndefined } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { db, storage } from 'src/_firebase/firebase';
import jobsTitles from 'src/common/jobsTitles';
import AddNewPostCard from 'src/components/KnowledgeCommunity/InternalCommunity/AddNewPostCard';
import AttachedFileViewDialog from 'src/components/KnowledgeCommunity/InternalCommunity/AttachedFileViewDialog';
import CommunityPostsList from 'src/components/KnowledgeCommunity/InternalCommunity/CommunityPostsList';
import InternalOrgCommunityToolbar from 'src/components/KnowledgeCommunity/InternalCommunity/InternalOrgCommunityToolbar';
import getKnowledgeTests from 'src/components/KnowledgeCommunity/InternalCommunity/KnowledgeTestDialog/getKnowledgeTests';
import saveKnowledgeTest from 'src/components/KnowledgeCommunity/InternalCommunity/KnowledgeTestDialog/saveKnowledgeTest';
import OrgCommunityPostsTabs from 'src/components/KnowledgeCommunity/InternalCommunity/OrgCommunityPostsTabs';
import NewPostsUpdatesCard from 'src/components/KnowledgeCommunity/InternalCommunity/RecentUpdates/NewPostsUpdatesCard';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import containsSomeLabels from 'src/utils/containsSomeLabels';
import getOrgRef from 'src/utils/getOrgRef';
import getUserJobTitle from 'src/utils/getUserJobTitle';
import isAuthorized from 'src/utils/isAuthorized';

const handleUploadFileToStorage = async (path, file, metadata = {}) => {
  const storageRef = ref(storage, path);
  return uploadBytes(storageRef, file, {
    customMetadata: {
      ...metadata
    }
  })
    .then(() => ({ state: 'success' }))
    .catch((err) => {
      console.log(err);
      return { state: 'err', err };
    });
};

const handleSaveNewPostToDb = async (
  postValues,
  org_ref,
  author_ref,
  setSnackbarState,
  snackbarState
) => {
  const orgCommunityRef = collection(db, `orgs/${org_ref}/community`);
  const { file, file_name, ...rest } = postValues;

  const dataToSave = file
    ? {
        ...rest,
        file: {
          file_name: file_name || file.name,
          type: file.type
        }
      }
    : { ...rest };
  const timestamp = serverTimestamp();
  return addDoc(orgCommunityRef, {
    ...dataToSave,
    created_at: timestamp,
    last_modified: timestamp,
    author_ref,
    org_ref
  })
    .then(async (res) => {
      if (file) {
        const uploadState = await handleUploadFileToStorage(
          `orgs/${org_ref}/community/${res.id}`,
          file.file,
          {
            original_file_name: file.name
          }
        );
        if (uploadState && uploadState.state === 'success') {
          return { state: 'success', post_id: res.id };
        }
        if (uploadState && uploadState.state === 'err') {
          setSnackbarState({
            ...snackbarState,
            open: true,
            severity: 'error',
            msg: 'הפוסט נשמר אך לא הצלחנו להעלות את הקובץ, נא לנסות שוב'
          });
          return { state: 'err', post_id: res.id, err: uploadState.err };
        }
      }
      return { state: 'success', post_id: res.id };
    })
    .catch((err) => {
      console.log(err);
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'error',
        msg: 'לא הצלחנו לצור את הפוסט, נא לנסות שוב'
      });
      return { state: 'err', err };
    });
};

const InternalOrgCommunity = () => {
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();
  const { snackbarState, setSnackbarState } = useSnackbar();
  // const [shownPosts, setShownPosts] = useState();
  const [tabValue, setTabValue] = useState('regular');
  const [communityItems, setCommunityItems] = useState();
  const [attachedFileToView, setAttachedFileToView] = useState();
  const [signViewIsOpen, setSignViewIsOpen] = useState();

  const handleSaveNewPost = async (newPostValues, setNewPostValues) => {
    const org_ref = getOrgRef(currentUser, selectedOrg);
    let details;
    if (tabValue === 'knowledge_tests' && newPostValues.knowledgeTest) {
      details = await saveKnowledgeTest(
        newPostValues,
        org_ref,
        currentUser.user_doc_id
      );
    } else {
      details = await handleSaveNewPostToDb(
        {
          ...newPostValues,
          signature_required: tabValue === 'signature_required'
        },
        org_ref,
        currentUser.user_doc_id,
        setSnackbarState,
        snackbarState
      );
    }
    const { state, post_id, type } = details;
    if (state === 'success') {
      setNewPostValues({
        description: '',
        signature_required: false,
        duration: 0
      });
      const temp = [...communityItems];
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'success',
        msg: 'הפרסום נשמר בהצלחה'
      });
      const { file, file_name, ...rest } = newPostValues;

      const dataToSave = file
        ? {
            ...rest,
            file: {
              file_name: file_name || file.name,
              type: file.type
            }
          }
        : { ...rest };

      if (dataToSave.knowledgeTest && tabValue === 'knowledge_tests') {
        temp.unshift({
          ...dataToSave,
          created_at: {
            seconds: moment().unix()
          },
          post_id,
          knowledge_test_id: post_id,
          author_ref: currentUser.user_doc_id,
          org_ref
        });
      }
      if (dataToSave.signature_required && tabValue === 'signature_required') {
        temp.unshift({
          ...dataToSave,
          created_at: {
            seconds: moment().unix()
          },
          post_id,
          author_ref: currentUser.user_doc_id,
          org_ref
        });
      }
      if (!dataToSave.signature_required && tabValue === 'regular') {
        temp.unshift({
          ...dataToSave,
          created_at: {
            seconds: moment().unix()
          },
          post_id,
          author_ref: currentUser.user_doc_id,
          org_ref
        });
      }
      setCommunityItems(temp);
      setNewPostValues({
        description: '',
        signature_required: false,
        duration: 0
      });
    } else if (state === 'err') {
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'error',
        msg: 'לא הצלחנו לשמור את הפרסום, נא לנסות שוב!'
      });
    }
  };

  // const filterPosts = (viewToKeep, list) =>
  //   list.filter((el) => el.signature_required !== (viewToKeep === 'regular'));
  const retrieveSelectedOrgInternalCommunity = async (newView) => {
    const org_ref = getOrgRef(currentUser, selectedOrg);

    try {
      if (newView === 'knowledge_tests') {
        const knowledgeTests = await getKnowledgeTests(
          org_ref,
          currentUser.user_doc_id,
          [getUserJobTitle(currentUser, org_ref)]
        );
        setCommunityItems(knowledgeTests);
        return;
      }

      const orgCommunityRef = collection(db, `orgs/${org_ref}/community`);
      const isSignatureRequiredTab = tabValue === 'signature_required';
      const queryConstraints = [
        where('signature_required', '==', Boolean(isSignatureRequiredTab)),
        orderBy('last_modified', 'desc')
      ];

      const q = query(orgCommunityRef, ...queryConstraints);
      const snap = await getDocs(q);

      if (snap.empty) {
        setCommunityItems([]);
        return;
      }

      const items = snap.docs.map((communityItem) => {
        const data = communityItem.data();
        const labels = data.labels || jobsTitles.map((el) => el.name);
        const postItem = {
          ...data,
          post_id: communityItem.id,
          labels,
          post_published: isUndefined(data.post_published)
            ? true
            : data.post_published
        };

        // Signature required tab with personal user
        if (isSignatureRequiredTab && currentUser.type !== 'org') {
          const { valid_for_all_employees, author_ref } = data;

          if (
            currentUser.user_doc_id === author_ref ||
            valid_for_all_employees ||
            containsSomeLabels(labels, [getUserJobTitle(currentUser, org_ref)])
          ) {
            return postItem;
          }
          return null;
        }

        // General logic
        return postItem;
      });

      // Filter non-null and only published posts
      const filteredItems = items
        .filter(
          (item) =>
            item && (isUndefined(item.post_published) || item.post_published)
        )
        .filter((el) => !el.knowledgeTest);

      setCommunityItems(filteredItems);
    } catch (error) {
      console.error('Error retrieving community items:', error);
      setCommunityItems([]);
    }
  };

  const handleOpenSignViewDialog = (post) => {
    setSignViewIsOpen(post);
  };
  const handleChangeTabValue = (newView) => {
    // const filtered = filterPosts(newView, communityItems);
    // setShownPosts(filtered);
    setTabValue(newView);

    if (
      newView === 'regular' ||
      newView === 'signature_required' ||
      newView === 'knowledgeTest'
    ) {
      setCommunityItems();
      retrieveSelectedOrgInternalCommunity(newView);
    }
  };

  useEffect(() => {
    if (currentUser && tabValue) {
      if (currentUser.type === 'personal') {
        if (selectedOrg && selectedOrg !== 'null') {
          retrieveSelectedOrgInternalCommunity(tabValue);
        }
      } else {
        retrieveSelectedOrgInternalCommunity(tabValue);
      }
    }
  }, [selectedOrg, currentUser, tabValue]);

  const handleSaveFavoritePostToDb = async (
    post_id_to_favorite,
    newFavoriteState
  ) => {
    const org_ref = getOrgRef(currentUser, selectedOrg);
    const orgCommunityRef = doc(
      db,
      `orgs/${org_ref}/community/${post_id_to_favorite}/likes/${currentUser.user_doc_id}`
    );

    try {
      // Use Firestore transaction to ensure atomic updates
      await runTransaction(db, async (transaction) => {
        // Get current document data to check existing values
        const postDoc = await transaction.get(
          doc(db, `orgs/${org_ref}/community/${post_id_to_favorite}`)
        );
        if (!postDoc.exists()) {
          throw new Error('Post does not exist!');
        }

        // Get the current favorite_sum from the post
        const currentFavoriteSum = postDoc.data().favorite_sum || 0;

        // Update the 'likes' subcollection (favorite state for the user)
        transaction.set(orgCommunityRef, {
          ...newFavoriteState,
          last_modified: serverTimestamp(),
          user_ref: currentUser.user_doc_id,
          org_ref:
            currentUser.type === 'personal'
              ? selectedOrg
              : currentUser.user_doc_id
        });

        // Calculate the increment or decrement for favorite_sum
        let newFavoriteSum = currentFavoriteSum;

        if (newFavoriteState.state) {
          // If liking, increment the favorite_sum
          newFavoriteSum += 1;
        } else {
          // If unliking, check if there are other likes, or reset to 0
          if (currentFavoriteSum <= 1) {
            newFavoriteSum = 0; // Reset to 0 if it's the only like
          } else {
            newFavoriteSum -= 1; // Otherwise, just decrement
          }
        }

        // Update the post's favorite sum in Firestore
        transaction.update(
          doc(db, `orgs/${org_ref}/community/${post_id_to_favorite}`),
          {
            favorite_sum: newFavoriteSum
          }
        );
      });
    } catch (err) {
      console.error('Error saving favorite state:', err);
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'error',
        msg: 'לא הצלחנו לשמור את הלייק שלך'
      });
    }
  };

  const handleFavoriteItem = async (postToFavorite) => {
    const temp = [...communityItems];
    const index = temp.findIndex((el) => el.post_id === postToFavorite.post_id);

    if (index > -1) {
      const currentFavoriteState = temp[index].favorite?.state || false;
      const newFavoriteState = {
        state: !currentFavoriteState, // Toggle the favorite state
        last_modified: {
          seconds: moment().unix()
        }
      };

      // Update the favorite state in the local state optimistically
      temp[index].favorite = newFavoriteState;

      // Adjust the favorite_sum based on the current and new favorite state
      const newFavoriteSum = temp[index].favorite_sum;

      if (!temp[index].favorite_sum) {
        temp[index].favorite_sum = 0;
      }

      if (newFavoriteState.state) {
        // Liking
        temp[index].favorite_sum += 1;
      } else {
        // Unliking
        if (temp[index].favorite_sum === 1) {
          // If it's the only like, set favorite_sum to 0
          temp[index].favorite_sum = 0;
        } else {
          temp[index].favorite_sum -= 1;
        }
      }

      // Update the local state in the UI optimistically
      setCommunityItems(temp);

      // Save the favorite state to Firestore
      await handleSaveFavoritePostToDb(
        postToFavorite.post_id,
        newFavoriteState
      );
    }
  };

  const handleRetrieveFileUrl = async (postToGet) => {
    // console.log(postToGet);
    const { post_id, org_ref } = postToGet;
    const storageRef = ref(storage, `orgs/${org_ref}/community/${post_id}`);
    return getDownloadURL(storageRef)
      .then((res) => {
        const temp = [...communityItems];
        const index = temp.findIndex((el) => el.post_id === post_id);
        if (index > -1) {
          if (temp[index].file) {
            temp[index].file.file_url = res;
          }
          setCommunityItems(temp);
          return res;
        }
        return res;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  };

  const handleViewCommunityAttachedFile = async (
    postToView,
    shouldSign = true
  ) => {
    // console.log(postToView);
    const { file } = postToView;
    if (file) {
      if (file.file_url) {
        setAttachedFileToView({
          ...postToView,
          file: { ...postToView.file, file_url: file.file_url }
        });
      } else {
        const urlRes = await handleRetrieveFileUrl(postToView);
        // console.log(res);
        setAttachedFileToView({
          ...postToView,
          file: { ...postToView.file, file_url: urlRes }
        });
      }
    } else {
      const { knowledgeTest } = postToView;
      if (knowledgeTest) {
        setAttachedFileToView(postToView);
      }
      if (shouldSign && !knowledgeTest) {
        // we dont need the sign dialog when there is knowledge test
        handleOpenSignViewDialog(postToView);
      }
    }
  };

  const handleCloseAttachedFileViewDialog = () => {
    setAttachedFileToView();
  };

  return (
    <>
      <Paper
        sx={{
          p: 3,
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.shadows[0]
        }}
      >
        <Grid
          container
          sx={{ display: 'flex', justifyContent: 'center' }}
          spacing={2}
        >
          <Grid item xs={12}>
            <InternalOrgCommunityToolbar />
          </Grid>
          <Grid item container spacing={2} xs={12}>
            <Grid item container xs={12} md={8} spacing={2}>
              <Grid item xs={12}>
                <OrgCommunityPostsTabs
                  tabValue={tabValue}
                  handleChangeTabValue={handleChangeTabValue}
                />
              </Grid>
              {isAuthorized(currentUser, 'practices:write', selectedOrg) && (
                <Grid item xs={12}>
                  <AddNewPostCard
                    activeTab={tabValue}
                    handleSaveNewPost={handleSaveNewPost}
                    posts={tabValue === 'knowledge_tests' ? communityItems : []}
                  />
                </Grid>
              )}
              <Grid item xs={12} container spacing={2}>
                <CommunityPostsList
                  handleViewCommunityAttachedFile={
                    handleViewCommunityAttachedFile
                  }
                  signViewIsOpen={signViewIsOpen}
                  setSignViewIsOpen={setSignViewIsOpen}
                  attachedFileToView={attachedFileToView}
                  setAttachedFileToView={setAttachedFileToView}
                  handleFavoriteItem={handleFavoriteItem}
                  posts={communityItems}
                  setPosts={setCommunityItems}
                />
              </Grid>
            </Grid>
            <Grid
              sx={{
                height: 'fit-content !important'
              }}
              item
              container
              xs={12}
              md={4}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sx={{
                  height: 'fit-content !important'
                }}
              >
                <NewPostsUpdatesCard
                  handleViewCommunityAttachedFile={
                    handleViewCommunityAttachedFile
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {attachedFileToView && (
        <AttachedFileViewDialog
          open={Boolean(attachedFileToView)}
          onClose={handleCloseAttachedFileViewDialog}
          post={attachedFileToView}
          handleOpenSignViewDialog={handleOpenSignViewDialog}
        />
      )}
    </>
  );
};

export default InternalOrgCommunity;
